import { useContext, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import axios from "axios";
import { Grid, Typography, Button, TextField, Paper } from "@mui/material";

import signInImage from "../../../../images/landing-page.jpg"


const SignIn = () => {
  const [signInData, setSignIndata] = useState({
    username: "",
    password: ""
  });
  const [error, setError] = useState("");
  const findUser = useContext(UserContext)?.fetchUser;
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.haydenstrivia.com' : 'http://localhost:3001';

  const fetchCsrfToken = async () => {
    const response = await fetch(`${baseUrl}/csrf_token`, {
      credentials: 'include',
    });
    const data = await response.json();
    return data.csrfToken;
  };

  function handleSignInChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSignIndata({
      ...signInData,
      [e.target.name]: e.target.value
    });
  }

  const signIn = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      console.log(csrfToken);
      const response = await axios.post(`${baseUrl}/sessions`, 
        {
          user: {
            username: signInData.username,
            password: signInData.password
          }
        }, 
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          withCredentials: true
        }
      );

      if (response.status === 201) {
        localStorage.setItem("userId", response.data.session);
        findUser && findUser();
      }
    } catch (error) {
      console.log("login error", error);
      setError("Login failed. Please check your credentials and try again.");
    }
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    signIn();
  }

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item xs={0} md={6}>
        <img src={signInImage} alt="Sign In" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      <Grid item xs={12} md={6} component={Paper} elevation={6} square>
        <div style={{ margin: 'auto', padding: '20px', maxWidth: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '20px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={handleSignInChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleSignInChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: '20px' }}
            >
              Sign In
            </Button>
            {error && <Typography color="error" style={{ marginTop: '10px' }}>{error}</Typography>}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignIn;
