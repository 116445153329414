import React from "react";
import "../../../../App.css";
import { Checkbox, FormGroup, FormControlLabel, Button } from "@mui/material";

const CreateTriviaGamePage: React.FC = () => {
  return (
    <div className="page-container flex-column">
      <div className="flex-row">
        <div>
          <h3>Select Rounds 1-4</h3>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Science"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Art and Literature"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="History"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked size="small" />}
              label="Food & Beverage"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked size="small" />}
              label="Not So Common Sense"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked size="small" />}
              label="Lightening Fill In The Blank"
            />
          </FormGroup>
        </div>
        <div>
          <h3>Select Final Trivia Categories</h3>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Science"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Art and Literature"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="History"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Food & Beverage"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Not So Common Sense"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Technology"
            />
          </FormGroup>
        </div>
      </div>

      <Button variant="outlined" className="w-100">
        Generate Trivia Game
      </Button>
    </div>
  );
};

export default CreateTriviaGamePage;
