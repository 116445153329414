import React from "react";
import Grid from "@mui/material/Grid";
import "../styles/questions.css";

import Questions from "../components/Questions";
import NewQuestionForm from "../components/NewQuestionForm";

const Home: React.FC = () => {
  const renderContent = () => {
    return (
      <Grid container className="questions-container" sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}>
        <Grid item xs={11}>
          <NewQuestionForm />
          <Questions />
        </Grid>
      </Grid>
    );
  } 

  return <div>{renderContent()}</div>;
};

export default Home;
