import React, { useContext, useState } from "react";
import "../../../../App.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  FormGroup,
  Chip,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { QuestionsContext } from "../contexts/QuestionsContext";
import { CategoriesContext } from "../../categories/contexts/CategoriesContext";

const NewQuestionForm: React.FC = () => {
  const questionsContext = useContext(QuestionsContext);
  const categoriesContext = useContext(CategoriesContext);
  const [buttonText, setButtonText] = useState("Add Question");

  const [formValues, setFormValues] = useState({
    question: "",
    answer: "",
    categories: [] as string[],
  });

  const handleCategoryToggle = (categoryId: number) => {
    const categoryIdStr = categoryId.toString();
    setFormValues((prevValues) => {
      const categories = prevValues.categories.includes(categoryIdStr)
        ? prevValues.categories.filter((id) => id !== categoryIdStr)
        : [...prevValues.categories, categoryIdStr];
      return { ...prevValues, categories };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (questionsContext?.addQuestion) {
      const result = await questionsContext.addQuestion(formValues);

      if (result.success) {
        setButtonText("Success!");
        setFormValues({
          question: "",
          answer: "",
          categories: [] as string[],
        });
  
        setTimeout(() => {
          setButtonText("Add Question");
        }, 3000);
      } else {
        setButtonText("Failed to add question.");
      }
    }
  };

  return (
    <Accordion sx={{ backgroundColor: "#f2f2f2", mt: 2, mb: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Add Questions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Typography variant="body1" fontWeight="bold">
                Question
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formValues.question}
                onChange={(e) =>
                  setFormValues({ ...formValues, question: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="body1" fontWeight="bold">
                Answer
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formValues.answer}
                onChange={(e) =>
                  setFormValues({ ...formValues, answer: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="body1" fontWeight="bold">
                Categories
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
            <FormControl component="fieldset">
              <FormGroup>
                <Stack 
                  direction="row" 
                  spacing={1} 
                  flexWrap="wrap" 
                  rowGap={2}
                >
                  {categoriesContext?.categories.map((category) => (
                    <Chip
                      key={category.id}
                      label={category.name}
                      clickable
                      color={
                        formValues.categories.includes(category.id.toString())
                          ? "primary"
                          : "default"
                      }
                      onClick={() => handleCategoryToggle(category.id)}
                    />
                  ))}
                </Stack>
              </FormGroup>
            </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default NewQuestionForm;
