import React, { createContext, Dispatch, SetStateAction, useState, ReactNode } from "react";

export interface Category {
  id: number;
  name: string;
}

interface CategoriesContextType {
  categories: Category[];
  setCategories: Dispatch<SetStateAction<Category[]>>;
  fetchCategories: () => Promise<void>;
}

const CategoriesContext = createContext<CategoriesContextType | undefined>(undefined);

interface CategoriesProviderProps {
  children: ReactNode;
}

const CategoriesProvider: React.FC<CategoriesProviderProps> = ({ children }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${baseUrl}/categories`);
      const categories = await response.json();
      setCategories(categories);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  return (
    <CategoriesContext.Provider value={{ categories, setCategories, fetchCategories }}>
      {children}
    </CategoriesContext.Provider>
  );
  
}

export { CategoriesContext, CategoriesProvider };







