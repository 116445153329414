import React, { createContext, Dispatch, SetStateAction, useEffect, useState, ReactNode, useCallback } from "react";
import axios from "axios";

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
}

interface UserContextType {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  fetchUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const UserProvider: React.FC<UserProviderProps> = ({ children, setLoggedIn }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchUser = useCallback(async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        setLoggedIn(false);
        setLoading(false);
        return;
      }

      const response = await axios.get(`${baseUrl}/logged_in/${userId}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        setUser(response.data.user);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
      setLoggedIn(false);
    } finally {
      setLoading(false);
    }
  }, [baseUrl, setLoggedIn]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <UserContext.Provider value={{ user, setUser, fetchUser }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };