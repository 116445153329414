import React, { createContext, Dispatch, SetStateAction, useState, ReactNode } from "react";
import { Category } from "../../categories/contexts/CategoriesContext";

export interface Question {
  id: number;
  question: string;
  answer: string;
  categories: Category[];
  created_at: Date;
  bonus: boolean;
  final_trivia: boolean;
  double_jeopardy: boolean;
}

interface QuestionsContextType {
  questions: Question[];
  setQuestions: Dispatch<SetStateAction<Question[]>>;
  fetchQuestions: () => Promise<void>;
  handleDelete: (rowId: number) => Promise<void>;
  addQuestion: (formValues: {}) => Promise<{ success: boolean; }>;
}

const QuestionsContext = createContext<QuestionsContextType | undefined>(undefined);

interface QuestionsProviderProps {
  children: ReactNode;
}

const QuestionsProvider: React.FC<QuestionsProviderProps> = ({ children }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${baseUrl}/questions`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const questions = await response.json();
      console.log(questions);
      setQuestions(questions);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    }
  };

  const handleDelete = async (rowId: number) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${baseUrl}/questions/${rowId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete question');
      }

      setQuestions((questions) =>
        questions.filter((question) => question.id !== rowId)
      );
    } catch (error) {
      console.error("Failed to delete question:", error);
    }
  };

  const addQuestion = async (
    formValues: {},
  ) => {
    try {
      const response = await fetch(`${baseUrl}/questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
  
      if (!response.ok) {
        throw new Error("Failed to add question");
      }
  
      const question = await response.json();
      setQuestions((questions) => [question, ...questions]);
      return { success: true };
    } catch (error) {
     console.log(`Failed to add question: ${error}`);
     return { success: false };
    }
  };
  

  return (
    <QuestionsContext.Provider value={{ questions, setQuestions, fetchQuestions, handleDelete, addQuestion }}>
        {children}
    </QuestionsContext.Provider>
  );
  
}

export { QuestionsContext, QuestionsProvider };






