import React, { useContext, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box, Grid } from "@mui/material";
import { UserContext } from "../../users/contexts/UserContext";
import logo from "../../../../images/logo_transparent.jpeg"

interface NavBarProps {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const NavBar: React.FC<NavBarProps> = ({setLoggedIn}) => {
  let navigate = useNavigate();
  const location = window.location.pathname;
  const locations = ["/", "/categories"];
  const [value, setValue] = React.useState(locations.indexOf(location));
  const setUser = useContext(UserContext)?.setUser;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSignOut = async () => {
    try {
      await fetch(`${baseUrl}/sign_out`, {
        method: "DELETE"
      });

      navigate(`/`);
      setLoggedIn(false);
      setUser && setUser(null);
      localStorage.clear();
    } catch (error: any) {
      console.error("Failed to fetch user:", error);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }} style={{ width: "100%"}}>
      <Grid container alignItems="stretch">
        <Grid item>
          <img src={logo} alt="Hayden's Trivia Logo" style={{ height: 80, paddingTop: 10 }} />
        </Grid>
        <Grid item xs display={"flex"} justifyContent={"flex-end"} alignItems={"flex-start"} style={{ paddingRight: "30px"}}>
          <Tabs
            TabIndicatorProps={{
              style: { backgroundColor: "#1976d2", color: "#1976d2" },
            }}
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab label="Questions" onClick={() => navigate("/")} />
            <Tab label="Categories" onClick={() => navigate("/categories")} />
            <Tab label="Create Trivia Game" onClick={() => navigate("/createTriviaGame")} />
            <Tab label="Sign Out" onClick={handleSignOut} style={{ marginLeft: "30px"}}/>
          </Tabs>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavBar;
