import React, { useContext, useState, useEffect, useRef } from "react";
import "../styles/questions.css";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { TableStyles } from 'react-data-table-component';
import "react-data-table-component-extensions/dist/index.css";
import dayjs from "dayjs";

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers';

import { QuestionsContext, Question } from "../contexts/QuestionsContext";
import { CategoriesContext } from "../../categories/contexts/CategoriesContext";

import EditableCell from "./EditableCell";
import { Button } from "@mui/material";

const isToday = (date: string | Date) => {
  const today = dayjs().startOf('day');
  const dateToCheck = dayjs(date).startOf('day');
  return dateToCheck.isSame(today);
};

const tableCustomStyles: TableStyles = {
  cells: {
    style: {
      paddingTop: '12px',
      paddingBottom: '12px',
      width: '300px',
      whiteSpace: 'normal' as const,
      overflowWrap: 'break-word',
    },
  },
  headCells: {
    style: {
      whiteSpace: 'normal' as const,
      overflowWrap: 'break-word',
      fontSize: '1rem',
    },
  },
};

const Questions: React.FC = () => {
  const hasFetchedData = useRef(false);
  const questionsContext = useContext(QuestionsContext);
  const categoriesContext = useContext(CategoriesContext);
  const [filterCategory, setFilterCategory] = useState("");
  // const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [questionTypeFilter, setQuestionTypeFilter] = useState("");
  const [bonusFilter, setBonusFilter] = useState(Boolean);
  const [finalTriviaFilter, setFinalTriviaFilter] = useState(Boolean);
  const [doubleJeopardyFilter, setDoubleJeopardyFilter] = useState(Boolean);
  // const [date, setDate] = useState(null);
  const [editingId, setEditingId] = useState<number | null>(null); // Track the row being edited
  const [editedRow, setEditedRow] = useState<Partial<Question>>({}); // Store the edited values

  useEffect(() => {
    if (!hasFetchedData.current) {
      categoriesContext?.fetchCategories();
      questionsContext?.fetchQuestions();
      hasFetchedData.current = true;
    }
  }, [categoriesContext, questionsContext, hasFetchedData])

  const filterResults = (questionsContext?.questions || []).filter(
    (question: Question) =>
      (filterCategory === "" ||
      question.categories.some((category: { name: string }) => category.name === filterCategory)) &&
      // (filterDate === null || new Date(question.created_at) >= filterDate) &&
      (bonusFilter === false || question.bonus) &&
      (finalTriviaFilter === false || question.final_trivia) &&
      (doubleJeopardyFilter === false || question.double_jeopardy)
  );

  const handleSetQuestionTypeFilter = (questionType: string) => {
    setQuestionTypeFilter(questionType);
    setBonusFilter(false);
    setFinalTriviaFilter(false);
    setDoubleJeopardyFilter(false);
    switch(questionType) {
      case "Bonus":
        setBonusFilter(true);
        break;
      case "Final Trivia":
        setFinalTriviaFilter(true);
        setBonusFilter(false);
        break;
      case "Double Jeopardy":
        setDoubleJeopardyFilter(true);
        break;
      default:
       return
    }
  };

  const handleEdit = (rowId: number) => {
    setEditingId(rowId);
    const currentRow = questionsContext?.questions.find((q) => q.id === rowId);
    setEditedRow(currentRow || {});
  };

  const handleSave = async (rowId: number) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${baseUrl}/questions/${editedRow.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedRow),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update question');
      }
  
      const updatedQuestion = await response.json();
  
      questionsContext?.setQuestions((questions) =>
        questions.map((question) =>
          question.id === updatedQuestion.id ? updatedQuestion : question
        )
      );
  
      setEditingId(null);
      setEditedRow({});
    } catch (error) {
      console.error("Failed to update question:", error);
    }
  };

  const handleDelete = async (rowId: number) => {
    try {
      await questionsContext?.handleDelete(rowId);
      setEditingId(null);
      setEditedRow({});
    } catch (error) {
      console.error("Failed to delete question:", error);
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditedRow({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedRow((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const conditionalRowStyles = [
    {
      when: (row: Question) => isToday(row.created_at),
      style: {
        backgroundColor: '#f0f8ff',
        color: 'black',
      },
    },
  ];

  const columns = [
    {
      cell: (row: Question) =>
        editingId === row.id ? (
          <div className="edit-cell">
            <Button
              sx={{
                fontSize: '12px',
                padding: '4px 8px',
                color: 'primary',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                },
              }}
              onClick={() => handleSave(row.id)}>
              Save
            </Button>
            <Button
              sx={{
                fontSize: '12px',
                padding: '4px 8px',
                color: 'primary',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                },
              }}
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button 
              sx={{
                fontSize: '12px',
                padding: '4px 8px',
                color: 'red',
                '&:hover': {
                  backgroundColor: 'red',
                  color: 'white',
                },
              }}
              onClick={() => handleDelete(row.id)}>
              Delete
            </Button>
          </div>
        ) : (
          <Button
            sx={{
              fontSize: '12px',
              padding: '4px 8px',
              color: 'primary',
              '&:hover': {
                backgroundColor: '#1976d2',
                color: 'white',
              },
            }}
            onClick={() => handleEdit(row.id)}>
            Edit
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "100px",
    },
    {
      name: "Categories",
      cell: (row: Question) =>
        row.categories?.map((category: { name: string }) => category.name).join(", "),
      width: "200px",
    },
    {
      name: "Question",
      cell: (row: Question) =>
        editingId === row.id ? (
          <EditableCell
            name="question"
            value={editedRow.question || ""}
            onChange={handleChange}
          />
        ) : (
          row.question
        ),
    },
    {
      name: "Answer",
      cell: (row: Question) =>
        editingId === row.id ? (
          <EditableCell
            name="answer"
            value={editedRow.answer || ""}
            onChange={handleChange}
          />
        ) : (
          row.answer
        ),
    },
    {
      name: "Bonus",
      selector: (row: Question) => row.bonus,
      cell: (row: Question) => (
        <div className="checkmark-column">
          {row.bonus ? '✔️' : null}
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Double Jeopardy",
      selector: (row: Question) => row.double_jeopardy,
      cell: (row: Question) => (
        <div className="checkmark-column">
          {row.double_jeopardy ? '✔️' : null}
        </div>
      ),
      sortable: true,
      width: "175px",
    },
    {
      name: "Final Trivia",
      selector: (row: Question) => row.final_trivia,
      cell: (row: Question) => (
        <div className="checkmark-column">
          {row.final_trivia ? '✔️' : null}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Date Created",
      selector: (row: Question) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
  ];

  if (!questionsContext || !(questionsContext.questions.length > 0)) {
    return <p>Loading Questions...</p>;
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel id="category-select-label">Sort By Category</InputLabel>
            <Select
              labelId="category-select-label"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              label="Sort By Category"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Sort By Category</em>
              </MenuItem>
              {categoriesContext?.categories.map((category) => (
                <MenuItem value={category.name} key={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel id="category-select-label">Sort By Type</InputLabel>
            <Select
              labelId="category-select-label"
              value={questionTypeFilter}
              onChange={(e) => handleSetQuestionTypeFilter(e.target.value)}
              label="Sort By Question Type"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Sort By Question Type</em>
              </MenuItem>
              <MenuItem value={"Bonus"} key={"bonus"}>
                Bonus
              </MenuItem>
              <MenuItem value={"Double Jeopardy"} key={"double-jeopardy"}>
                Double Jeopardy
              </MenuItem>
              <MenuItem value={"Final Trivia"} key={"final-trivia"}>
                Final Trivia
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Sort By Date Created"
              value={"value"}
              onChange={(date) => (handleEdit())}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid> */}
      </Grid>
      {questionsContext && (
        <DataTableExtensions columns={columns} data={filterResults} print={false}>
          <DataTable
            columns={columns}
            data={filterResults}
            keyField="id"
            expandOnRowDoubleClicked
            fixedHeaderScrollHeight="300px"
            highlightOnHover
            noHeader
            pagination
            persistTableHead
            responsive
            selectableRowsHighlight
            selectableRowsNoSelectAll
            striped
            subHeaderWrap
            customStyles={tableCustomStyles}
            conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableExtensions>
      )}
    </>
  );
};

export default Questions;
