import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";

interface EditableCellProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({ name, value, onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const cursorPositionRef = useRef<number | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    cursorPositionRef.current = e.target.selectionStart;
    onChange(e);
  };

  useEffect(() => {
    if (inputRef.current && cursorPositionRef.current !== null) {
      inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }
  }, [value]);

  return (
    <TextField
      name={name}
      value={value}
      onChange={handleInputChange}
      fullWidth
      multiline
      inputRef={inputRef}
      sx={{
        fontSize: "12px",
        "& .MuiInputBase-root": {
          fontSize: "12px",
        },
      }}
      InputProps={{
        style: { fontSize: "12px" },
      }}
    />
  );
};

export default EditableCell;