import React from "react";
import { Routes, Route } from "react-router-dom";

import QuestionsPage from "../app/modules/questions/pages/QuestionsPage";
import CategoriesPage from "../app/modules/categories/pages/CategoriesPage";
import CreateTriviaGamePage from "../app/modules/createTriviaGame/pages/CreateTriviaGamePage";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/createTriviaGame" element={<CreateTriviaGamePage />} />
      <Route path="/categories" element={<CategoriesPage />} />
      <Route path="/" element={<QuestionsPage />} />
    </Routes>
  );
};

export default AppRoutes;
