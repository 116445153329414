import { Grid } from "@mui/material";
import React from "react";

const CategoriesPage: React.FC = () => {
  return (
    <Grid>
      <h1>Categories</h1>
    </Grid>
  );
};

export default CategoriesPage;
