import { Routes, Route } from "react-router-dom"
import SignIn from "../app/modules/users/pages/SignIn";

function SignInRoutes() {
    return (
      <div className="flex flex-col items-center justify-between bg-th-primary w-screen min-h-screen pt-5">
        <div className='flex flex-col items-center w-screen'>
          <Routes>
            <Route 
              path={`/`}
              element={<SignIn />}
            />
          </Routes>
        </div>
      </div>
    )
}

export default SignInRoutes;