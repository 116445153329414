import "./App.css";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Grid } from "@mui/material";

import AppRoutes from "./routes/AppRoutes";
import NavBar from "./app/modules/header-footer/components/NavBar";
import SignInRoutes from "./routes/SignInRoutes"

import { QuestionsProvider } from "./app/modules/questions/contexts/QuestionsContext";
import { CategoriesProvider } from "./app/modules/categories/contexts/CategoriesContext";
import { UserProvider } from "./app/modules/users/contexts/UserContext"

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <BrowserRouter>
      <UserProvider setLoggedIn={setLoggedIn}>
        {loggedIn ? (
          <CategoriesProvider>
            <QuestionsProvider>
              <Grid>
                <Grid display="flex" flexDirection="column" alignItems="center">
                  <NavBar setLoggedIn={setLoggedIn} />
                </Grid>
                <AppRoutes />
              </Grid>
            </QuestionsProvider>
          </CategoriesProvider>
        ) : (
          <SignInRoutes />
        )}
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
